import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  create_new_wdmr214 (data) {
    // console.log('create_new_wdmr214 service is trigerred');
    new Date().toISOString();
    var now = new Date();
    var isoString = now.toISOString();
    // console.log(isoString);
    return Vue.http.post(API_BASE_URL + 'conversation/create_new_wdmr214', data);
  },
  get_eligible_wdm22_list(data) {
    // console.log('get_eligible_wdm22_list service is trigerred');
    return Vue.http.post(API_BASE_URL + 'conversation/get_eligible_wdm22_list', data);
  },
  get_my_wdmr_214_list(data) {
    // console.log('get_my_wdmr_214_list service is trigerred');
    return Vue.http.post(API_BASE_URL + 'conversation/get_my_wdmr_214_list', data);
  },
  add_message_wdm213(data) {
    // console.log('add_message_wdm213 service is trigerred');
    return Vue.http.post(API_BASE_URL + 'conversation/add_message_wdm213', data);
  },
  show_wdm213(data) {
    // console.log('show_wdm213 service is trigerred');
    return Vue.http.post(API_BASE_URL + 'conversation/show_wdm213', data);
  },
  check_personal_conversation(data) {
    // console.log('check_personal_conversation service is trigerred')
    return Vue.http.post(API_BASE_URL + 'conversation/check_personal_conversation', data);
  },
  search_user(data) {
    // console.log('search_user service is trigerred')
    return Vue.http.post(API_BASE_URL + 'conversation/search_user', data);
  },
  offline_message_notification(data) {
    // console.log('search_user service is trigerred')
    return Vue.http.post(API_BASE_URL + 'conversation/offline_message_notification', data);
  },
  offline_clear_notification(data) {
    // console.log('clear notification service is trigerred')
    return Vue.http.post(API_BASE_URL + 'conversation/offline_clear_notification', data);
  }
};
 