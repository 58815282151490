<template>
  <div>
    <audio id='myAudio'>
      <source src="@/assets/notification_sounds/droplet.mp3" type="audio/mpeg" />
      <source src="@/assets/notification_sounds/droplet.ogg" type="audio/ogg" />
    </audio>
    <b-card no-body>
      <b-card-header header-bg-variant="dark" header-text-variant="white" class="p-1">
        <img src="@/icon/1519119.png" style="width: 1.5em" />
        <b-button class="pull-right" size="sm" :variant="d_meetingData && d_meetingData.id ? 'warning' : 'secondary'" @click="d_meetingScreen = true" :title="d_meetingData && d_meetingData.id ? 'TOPLANTI AKTİF' : 'TOPLANTIYA BAĞLANMAK TIKLAYINIZ'" style="margin-right: 5px">
          <img src="@/icon/1994875.png" style="width: 2em" /> toplantı
        </b-button>
        <template v-if="d_meetingData['id']">
          <b-button class="pull-right" size="sm" variant="secondary" @click="f_copyToClipboard(); $bvToast.show('meeting_id_copied');" title="toplantı linkini panonuza kopyalayınız" style="margin-right: 5px">
            <img src="@/icon/1994875.png" style="width: 2em" /> toplantı linkini kopyalayınız
          </b-button>
          <b-button class="pull-right" size="sm" variant="secondary" @click="d_meetingInvitationModal = true" title="Toplantınıza katılımcı davet edin" style="margin-right: 5px">
            <img src="@/icon/1994875.png" style="width: 2em" /> toplantı daveti
          </b-button>
        </template>
        </b-button>
        <template v-if="d_meetingData && d_meetingData.id">
          <template v-for="(talk, talk_ind) in d_talkingUsers">
            <div :id="'audio_' + talk" style="display: hidden"></div>
          </template>
          <b-button v-if="!d_sharingCamera" class="pull-right" size="sm" @click="f_shareCamera()" title="Kamerayı açmak için tıklayınız" style="margin-right: 5px">
            <img src="@/icon/22160.png" style="width: 2em" /> kamera aç
          </b-button>
          <b-button v-if="d_sharingCamera" class="pull-right" size="sm" @click="f_stopCameraShare()" title="Kamerayı kapatmak için tıklayınız" style="margin-right: 5px">
            <img src="@/icon/22160.png" style="width: 2em" /> kamera kapat
          </b-button>
          <b-button v-if="!d_sharingAudio" class="pull-right" size="sm" @click="f_shareAudio()" title="Sesi açmak için tıklayınız" style="margin-right: 5px">
            <img src="@/icon/22160.png" style="width: 2em" /> ses aç
          </b-button>
          <b-button v-if="d_sharingAudio" class="pull-right" size="sm" @click="f_stopAudioShare()" title="Sesi kapatmak için tıklayınız" style="margin-right: 5px">
            <img src="@/icon/22160.png" style="width: 2em" /> sesi kapat
          </b-button>
          <b-button v-if="!d_sharingScreen && !d_otherOneShare" class="pull-right" size="sm" @click="f_shareScreen()" title="Ekranı paylaşmak için tıklayınız" style="margin-right: 5px">
            <img src="@/icon/22160.png" style="width: 2em" /> ekran paylaş
          </b-button>
          <b-button v-if="d_sharingScreen" class="pull-right" size="sm" @click="f_stopScreenShare()" title="Ekran paylaşımını durdurmak için tıklayınız" style="margin-right: 5px">
            <img src="@/icon/22160.png" style="width: 2em" /> paylaşımı durdur
          </b-button>
          <template v-if="d_otherOneShare || (!d_otherOneShare && d_showMessengerMode === 'camera_screen')">
            <b-button v-if="d_otherOneShare && (d_showMessengerMode === 'camera_screen' || d_showMessengerMode === 'message_screen')" class="pull-right" size="sm" @click="d_showMessengerMode = 'share_screen'" title="paylaşım ekranı" :style="d_openCloseLight.show_messenger_mode ? 'margin-right: 5px; box-shadow: 0px 0px 4px 4px rgb(233, 253, 233);' : 'margin-right: 5px;'">
              <img src="@/icon/22160.png" style="width: 2em" /> paylaşım ekranı
            </b-button>
            <b-button v-if="d_showMessengerMode === 'share_screen' || d_showMessengerMode === 'camera_screen'" class="pull-right" size="sm" @click="d_showMessengerMode = 'message_screen'" title="mesaj ekranı" style="margin-right: 5px">
              <img src="@/icon/22160.png" style="width: 2em" /> mesaj ekranı
            </b-button>
          </template>
          <b-button v-if="d_showMessengerMode === 'share_screen' || d_showMessengerMode === 'message_screen'" class="pull-right" size="sm" @click="d_showMessengerMode = 'camera_screen'" title="kamera ekranı" style="margin-right: 5px">
            <img src="@/icon/22160.png" style="width: 2em" /> kamera ekranı
          </b-button>
        </template>
      </b-card-header>
      <b-row style="margin: 0px">
        <b-col cols="3" style="padding: 3px">
          <b-card bg-variant="dark" style="margin-bottom: 0px" no-body>
            <b-card-header header-bg-variant="dark" header-text-variant="dark" class="p-1">
              <b-row style="margin: 0px">
                <b-col cols="6">
                  <i class="fa fa-file-text-o"></i>
                  <h4>
                    <b-badge variant="dark">
                      <template>
                        <div v-if="d_showUserMode === 'all'">Sohbetler</div>
                        <div v-else-if="d_showUserMode === 'conversations'">
                          Sohbetler
                        </div>
                      </template>
                    </b-badge>
                  </h4>
                </b-col>
                <b-col cols="6" variant="dark" style="padding: 3px; text-align: right">
                  <div>
                    <b-button @click="d_createGroupModal = true">Yeni Grup</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-card-header>
            <b-row>
              <b-col cols="12">
                <b-form-input v-model="d_searchText" placeholder="Arama...." style="background-color: transparent"></b-form-input>
              </b-col>
            </b-row>
            <div :style="f_calculateStyle()">
              <template v-if="!d_searchText && !d_groupMode">
                <template v-for="(object, object_ind) in c_filtered">
                  <template v-if="f_searchUser(user)">
                    <b-row :style="object_ind === selected_mes_ind ? 'cursor: pointer; min-height: 30px; background-color: #e5f1ec; margin: 0px; border-bottom: solid 1px #a8efc1;' : 'cursor: pointer; min-height: 30px; margin: 0px; border-bottom: solid 1px #a8efc1;'">
                      <b-col @click="f_selectThisUser(object_ind, object['key'])">
                        <div>
                          <strong>
                            <template>
                              <img :src="f_getProfilePictureFromWdm214(object)" class="img-avatar" style="width: 2em"/>
                              <template>
                                <span style="color: #d013fd">{{ f_nameStripper(object["label"]["conversation_name"]) }}</span>
                              </template>
                              <template v-if="object['label']['new_message'] != 0 && !d_visitedUsers.includes(object_ind.toString())">
                                offline yeni mesaj
                              </template>
                            </template>
                          </strong>
                        </div>
                        <b-badge v-if="object.new_message" variant="success">yeni</b-badge>
                      </b-col>
                    </b-row>
                  </template>
                </template>
              </template>
              <template v-else-if="d_searchText && !d_groupMode">
                <template v-for="(object, object_ind) in d_searchResult">
                  <template v-if="f_searchUser(user)">
                    <b-row :style="object_ind === selected_mes_ind ? 'cursor: pointer; min-height: 30px; background-color: #e5f1ec; margin: 0px; border-bottom: solid 1px #a8efc1;' : 'cursor: pointer; min-height: 30px; margin: 0px; border-bottom: solid 1px #a8efc1;'">
                      <b-col @click="f_checkIfPresents(object, object_ind)">
                        <strong>
                          <template>
                            <img :src="f_getProfilePictureFromWdm22Id(object.key)" class="img-avatar" style="width: 2em"/>
                            <template v-if="d_showUserMode === 'all'">
                              <span style="color: #d013fd">
                                {{ object["label"] }}
                              </span>
                            </template>
                            <template v-else-if="d_showUserMode === 'complete'">
                              <span style="color: #d013fd">
                                {{ object["label"] }}
                              </span>
                            </template>
                            <template v-else>
                              <span style="color: #d013fd">
                                {{ f_nameStripper(object["label"]) }}
                              </span>
                            </template>
                            <span v-if="object_ind === selected_mes_ind">
                              <template v-if="d_showUserMode === 'all'">
                                <b-button @click="f_createNewWdmr214(object['label'], object['value'])" variant="outline-dark">
                                  Sohbeti başlat
                                </b-button>
                              </template>
                            </span>
                          </template>
                        </strong>
                      </b-col>
                    </b-row>
                  </template>
                </template>
              </template>
              <template v-else-if="d_groupMode">
                <template v-for="(object, object_ind) in d_searchResult">
                  <template v-if="f_searchUser(user)">
                    <b-row :style="f_groupParticipantsStyle(object_ind)">
                      <b-col @dblclick="f_groupDecide(object_ind)">
                        <strong>
                          <template>
                            <img :src="f_getProfilePictureFromWdm22Id(object.key)" class="img-avatar" style="width: 2em"/>
                            <template v-if="d_showUserMode === 'all'">
                              <span style="color: #d013fd">
                                {{ object["label"] }} group mode is activated
                              </span>
                            </template>
                            <template v-else-if="d_showUserMode === 'complete'">
                              <span style="color: #d013fd">
                                {{ object["label"] }} group mode is activated
                              </span>
                            </template>
                            <template v-else>
                              <span style="color: #d013fd">
                                {{ f_nameStripper(object["label"]) }}
                              </span>
                              group mode is activated
                            </template>
                            <span v-if="object_ind === selected_mes_ind">
                              <template v-if="d_showUserMode === 'all'">
                                <b-button @click="f_createNewWdmr214(object['label'], object['value'])" variant="outline-dark">
                                  Sohbeti başlat
                                </b-button>
                              </template>
                            </span>
                          </template>
                        </strong>
                      </b-col>
                    </b-row>
                  </template>
                </template>
                <button @click="f_createGroupDoubleClick">Grubu yarat !</button>
              </template>
            </div>
          </b-card>
        </b-col>
        <b-col cols="9" style="padding: 3px">
          <b-card v-if="d_showMessengerMode === 'message_screen'" bg-variant="white" style="margin-bottom: 0px" no-body>
            <b-card-header style="background-color: #a2f3eb" header-text-variant="dark" class="p-1">
              <i class="fa fa-file-text-o"></i> Mesajlaşma Ekranı
            </b-card-header>
            <div id="messages-list" :style="f_calculateStyleMessageScreen()">
              <div>
                <template v-if="selected_mes_ind !== ''">
                  <template v-for="wdm213 in Object.values(d_selected_wdm213)">
                    <template v-for="(message, message_ind) in wdm213">
                      <b-row v-if="message && message['5']['val']['value']" style="margin: 0px; padding: 5px">
                        <b-col cols="6" style="padding-left: 0px">
                          <template v-if="message['5']['val']['value'] !== d_wdm22.id">
                            <div style="width: 100%; padding: 5px; color: black; font-size: 12px; border: solid 1px #b7b7b7; border-radius: 5px 5px; background-color: #d4e9ef;">
                              <img :src="f_getProfilePictureFromWdm22Id(message['5']['val']['value'])" class="img-avatar" style="width: 2em;  background-color: #ffffff80" />
                              {{ message["1"]["val"] }}
                            </div>
                            <b-row style="margin: 0px">
                              <b-col cols="12" style="padding: 0px">
                                <span style="font-size: 10px; background-color: #cee2e6; color: black; border: solid 1px #9e9e9e; border-radius: 5px 5px;">
                                  {{ DateFormat(message["6"]["val"]) }}
                                </span>
                              </b-col>
                            </b-row>
                            <b-row v-if="message.meeting_id" style="margin: 0px">
                              <b-col cols="12" style="padding: 0px">
                                <b-button class="pull-right" size="sm" variant="warning" @click="f_joinMeeting(message.meeting_id)">
                                  <img src="@/icon/1994875.png" style="width: 2em" /> Toplantıya katılmak için lütfen tıklayınız.
                                </b-button>
                              </b-col>
                            </b-row>
                          </template>
                        </b-col>
                        <b-col cols="6" style="padding-right: 0px">
                          <template v-if="message['5']['val']['value'] === d_wdm22.id">
                            <div style="width: 100%; padding: 5px; color: black; font-size: 12px; float: right; border: solid 1px #b7b7b7; border-radius: 5px 5px; background-color: #dbefd4;">
                              <img :src="profile_picture" class="img-avatar pull-right" style="width: 2em; background-color: #ffffff80" />
                              {{ message["1"]["val"] }}
                            </div>
                            <div class="pull-right">
                              <span style="font-size: 10px; background-color: #bcc7a4; color: black; border: solid 1px #9e9e9e; border-radius: 5px 5px;">
                                {{ DateFormat(message["6"]["val"]) }}
                              </span>
                            </div>
                          </template>
                        </b-col>
                      </b-row>
                    </template>
                  </template>
                </template>
              </div>
            </div>
            <b-row style="margin: 0px; padding: 3px">
              <b-col cols="12">
                <template v-for="(sen, sen_ind) in d_recommendNow">
                  <span style="margin-right: 20px; cursor: pointer; border-radius: 3px 3px; background-color: #9ae0dc; border: solid 1px #abafaf;">
                    {{ sen.label }}
                  </span>
                </template>
              </b-col>
            </b-row>
            <div>
              <b-form-group :disabled="selected_mes_ind === ''" style="margin: 0px">
                <b-input-group>
                  <b-form-textarea style="color: red; background-color: black" id="sending-text" @keydown.enter.prevent="f_sendMyMessage" v-model="d_sendText" placeholder="Mesajınızı yazınız..." rows="3" max-rows="6"></b-form-textarea>
                  <b-input-group-append>
                    <b-button @click="f_sendMyMessage(); f_offlineMessageNotification();" variant="dark">
                      <i class="fa fa-send"></i> Mesajı gönder
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card>
          <b-card v-if="d_showMessengerMode === 'share_screen'" bg-variant="white" style="margin-bottom: 0px" no-body :class="[d_screenSharingModejFullScreen ? 'full-screen-mode' : '']">
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              <i class="fa fa-file-text-o"></i> Ekran Paylaşım Modu
              <b-button variant="secondary" class="pull-right" @click="d_screenSharingModejFullScreen === true ? (d_screenSharingModejFullScreen = false) : (d_screenSharingModejFullScreen = true)" style="margin-right: 3px; padding: 0px; border: solid 1px #5d7d75;" :title="$t('wdm16.6589')">
                <img src="@/icon/900293.png" style="width: 2em" />
              </b-button>
            </b-card-header>
            <div id="video_element" :style="f_calculateStyleMessageScreen()">
              Ekran Paylaşım Alanı
            </div>
          </b-card>
          <b-card v-if="d_showMessengerMode === 'camera_screen'" bg-variant="white" style="margin-bottom: 0px" no-body :class="[d_cameraSharingModejFullScreen ? 'full-screen-mode' : '']">
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              <i class="fa fa-file-text-o"></i> Kamera Paylaşım Modu
              <b-button variant="secondary" class="pull-right" @click="d_cameraSharingModejFullScreen === true ? (d_cameraSharingModejFullScreen = false) : (d_cameraSharingModejFullScreen = true)" style="margin-right: 3px; padding: 0px; border: solid 1px #5d7d75;" :title="$t('wdm16.6589')">
                <img src="@/icon/900293.png" style="width: 2em" />
              </b-button>
            </b-card-header>
            <div :style="f_calculateStyleMessageScreen()">
              <b-row id="camera_area">
                <template v-for="(cam, cam_ind) in d_cameraUsers">
                  <div :id="'camera_' + cam.toString()" style="float: left; max-width: 300px"></div>
                </template>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <template v-if="d_meetingData">
          <b-col cols="2">
            <b-card bg-variant="dark" style="margin-bottom: 0px" header="Kullanıcılar" no-body>
              <b-card-body id="nav-scroller" ref="content" style="position: relative; height: 800px; overflow-y: scroll">
                <b-toast id="meeting_id_copied" class="pull-right" static auto-hide-delay="500">
                  <span style="color: black">Toplantı linki panoya kopyalandı!</span>
                </b-toast>
                <b-row>
                  <b-col>
                    <div>
                      <template v-for="object in d_meetingData['data']['general']['participant']['list']">
                        <b-jumbotron :key="object.participant_name.val.value" :lead="object.participant_name.val.label" class="m-2" text-variant="dark">
                          <p>
                            Kamera: {{ object.participant_camera.val.label }}
                          </p>
                          <p>
                            Mikrofon: {{ object.participant_audio.val.label }}
                          </p>
                          <p>
                            Ekran Paylaşımı: {{ object.participant_screen_sharing.val.label }}
                          </p>
                        </b-jumbotron>
                      </template>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </template>
      </b-row>
    </b-card>
    <b-modal centered class="modal-success" v-model="d_meetingScreen" @ok="d_meetingScreen = false" hide-footer>
      <template slot="modal-title">
        Toplantıya katıl
      </template>
      <template v-if="!d_meetingData">
        <b-row>
          <b-col cols="3"> <strong>Toplantıya Katıl</strong> </b-col>
          <b-col cols="6">
            <b-form-input type="text" class="form-control-warning" v-model="d_meetingId"></b-form-input>
          </b-col>
          <b-col cols="3">
            <b-button v-if="d_meetingId" size="sm" variant="secondary" @click="f_joinMeeting(d_meetingId)">
              <img src="@/icon/1994875.png" style="width: 2em" /> Katıl
            </b-button>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col cols="12">
            <b-button variant="secondary" @click="f_createNewMeeting()">yeni toplantı oluştur</b-button>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <b-row>
          <b-col cols="4">
            <b-button variant="secondary" @click="f_leaveMeeting('this')">Toplantıdan Ayrıl</b-button>
          </b-col>
          <b-col cols="4">
            <b-button variant="secondary" @click="f_copyToClipboard(); $bvToast.show('meeting_id_copied');">
              Toplantı linkini panoya kopyala
            </b-button>
          </b-col>
          <b-col cols="4">
            <b-button variant="secondary" @click="f_leaveMeeting('all')">
              Toplantıyı Sonlandır
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <b-modal v-model="d_meetingInvitationModal" scrollable @hide="f_clearGroupData">
      <template slot="modal-title">
        Toplantı davet ekranı
      </template>
      <p class="my-3">
        Toplantınıza davet etmek istediğiniz kullanıcıları aratınız!
      </p>
      <b-form-input v-model="d_searchText" placeholder="Arama..." style="background-color: transparent"></b-form-input>
      <template v-for="(object, object_ind) in d_searchResult">
        <template v-if="f_searchUser(user)">
          <b-row :style="f_groupParticipantsStyle(object_ind)">
            <b-col @click="f_groupDecide(object_ind)">
              <strong>
                <template>
                  <img :src="f_getProfilePictureFromWdm22Id(object.key)" class="img-avatar" style="width: 2em"/>
                  <template>
                    <span style="color: #d013fd">
                      {{ object["label"] }}
                    </span>
                    meeting invitation mode
                  </template>
                </template>
              </strong>
            </b-col>
          </b-row>
        </template>
      </template>
      <template slot="modal-footer">
        <b-button variant="danger" @click="f_clearGroupData()">{{ $t('wdm16.4053') }}</b-button>
        <b-button :disabled="d_selectedHighlightIds.length === 0" variant="success" @click="f_sendMeetingInvitation()">Daveti gönder</b-button>
      </template>
    </b-modal>
    <b-modal v-model="d_createGroupModal" scrollable @hide="f_clearGroupData">
      <template slot="modal-title">
        Yeni Grup
      </template>
      <b-form-input v-model="d_groupName" placeholder="Grup İsmi Yazınız" style="background-color: transparent"></b-form-input>
      <b-form-input v-model="d_searchText" placeholder="Arama..." style="background-color: transparent"></b-form-input>
      <template>
        <template v-for="(object, object_ind) in d_searchResult">
          <template v-if="f_searchUser(user)">
            <b-row :style="f_groupParticipantsStyle(object_ind)">
              <b-col @dblclick="f_groupDecide(object_ind)">
                <template>
                  <img :src="f_getProfilePictureFromWdm22Id(object.key)" class="img-avatar" style="width: 2em" />
                  <template v-if="d_showUserMode === 'all'">
                    <span style="color: #d013fd">
                                        {{ object["label"] }} group mode is activated
                                      </span>
                  </template>
                  <template v-else-if="d_showUserMode === 'complete'">
                    <span style="color: #d013fd">
                                        {{ object["label"] }} group mode is activated
                                      </span>
                  </template>
                  <template v-else>
                    <span style="color: #d013fd">
                                        {{ f_nameStripper(object["label"]) }}
                                      </span> group mode is activated
                  </template>
                  <template v-if="object_ind === selected_mes_ind">
                    <template v-if="d_showUserMode === 'all'">
                      <b-button @click="f_createNewWdmr214(object['label'], object['value'])" variant="outline-dark">Sohbeti başlat</b-button>
                    </template>
                  </template>
                </template>
              </b-col>
            </b-row>
          </template>
        </template>
      </template>
      <template slot="modal-footer">
        <b-button variant="danger" @click="f_clearGroupData()">{{ $t('wdm16.4053') }}</b-button>
        <b-button :disabled="c_disabled" variant="success" @click="f_createGroupDoubleClick()">Grup oluştur</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
var mediaScreenRecorder;
var mediaAudioRecorder;
var mediaCameraRecorder;

/*import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
*/
import { mapGetters } from "vuex";
import {
  default as router
} from "@/router";
// services
import OptionService from "@/services/option";
import MessengerService from "@/services/messenger";
import ConversationService from "@/services/conversation";
import moment from "moment";
import {
  default as Modal
} from "@/components/widgets/Modal";

export default {
  name: "Messenger",
  computed: {
    c_disabled: function () {
      if (this.d_groupName && this.d_groupHighlight.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    ...mapGetters({
      lang: "lang",
      messenger_data: "messenger_data",
      StoreModal: "modal",
      help: "help",
      StoreLoading: "loading",
      StoreTest: "test",
    }),
    c_filtered: function () {
      var filteredObjects = [];
      for (var object in this.d_myWdm214Objects) {
        if (this.d_checkConversation["full"]) {
          if (this.d_checkConversation["full"].includes(this.d_myWdm214Objects[object]["key"])) {
            filteredObjects.push(this.d_myWdm214Objects[object]);
          }
        }
      }
      return filteredObjects;
    },
  },
  props: {
    polestar: {
      type: Object,
      required: false,
    },
    organization_data: {
      type: Object,
      required: false,
    },
  },
  data () {
    return {
      d_profilePictureObj: {},
      d_selectedHighlightIds: [],
      d_wait: true,
      d_visitedUsers: [],
      d_groupHighlight: [],
      d_meetingInvitationModal: false,
      d_createGroupModal: false,
      d_groupMode: false,
      d_selectedSearchData: "old",
      d_searchResult: [],
      d_searchText: "",
      d_searchTimer: 0,
      d_valTextTimer: { timeout: "", text: "" },
      d_checkConversation: {},
      d_myUserObjects: {},
      d_myWdm214Objects: {},
      d_groupName: "",
      d_colors: [
        "danger",
        "warning",
        "info",
        "light",
        "dark",
        "primary",
        "secondary",
      ],
      d_createGroupWithThem: [],
      // bunu kullan
      d_selected_wdm213: {},
      d_myWdm214Key: "",
      d_userObject: {},
      d_userList: [],
      d_userIndexList: [],
      d_selectedAnatomySchemaList: [],
      d_screenSharingModejFullScreen: false,
      d_cameraSharingModejFullScreen: false,
      d_showUserMode: "all", // conversations, meeting, supporter
      d_hoverUserIndex: "",
      d_activeMeetingParticipants: [],
      d_meetingId: "",
      d_meetingScreen: false,
      d_recommendSelection: { start: 0, end: 2 },
      d_recommendationSentenceList: [{
          label: "hangi bilgiler seçildi",
          translation: { tr: "hangi bilgiler seçildi" },
        },
        { label: "sonlandır", translation: { tr: "sonlandır" } }, {
          label: "yeni analize başla",
          translation: { tr: "yeni analize başla" },
        },
        { label: "analiz yap", translation: { tr: "analiz yap" } },
        { label: "evet", translation: { tr: "evet" } },
        { label: "hayır", translation: { tr: "hayır" } },
        { label: "analiz yap", translation: { tr: "analiz yap" } },
      ],
      d_recommendNow: [],
      d_showAiRobots: false,
      selected_mes_ind: "",
      user_search: "",
      d_sendText: "",
      user: {},
      profile_picture: "",
      refreshIntervalId: "",
      wisdom_data: {},
      data_type: "wdm20",
      option_data: { wdm20: {} },
      d_pagination: {
        start: 0,
        end: 10,
        current: 1,
        perpage: 10,
      },
      d_meetingData: "",
      d_meetingLabel: "",
      d_shareScreenInterval: "",
      d_shareAudioInterval: "",
      d_shareCameraInterval: "",
      d_showMessengerMode: "message_screen",
      d_sharingScreen: false,
      d_sharingAudio: false,
      d_sharingCamera: false,
      d_otherOneShare: false,
      d_openCloseLight: {
        show_messenger_mode: false,
      },
      d_talkingUsers: [],
      d_cameraUsers: [],
      w_presentation: false,
      d_wdm22: "",
    };
  },
  created: function () {
    this.d_wdm22 = JSON.parse(localStorage.getItem("wdm22"));
    this.f_getEligibleWdm22List();
    this.f_get_my_wdmr_214_list();
    this.w_presentation = JSON.parse(localStorage.getItem("presentation"));
    this.user = JSON.parse(localStorage.getItem("user"));
    let prof_pic = localStorage.getItem("profile_picture");
    if (!prof_pic ||
      prof_pic === undefined ||
      prof_pic === "undefined" ||
      prof_pic === ""
    ) {
      this.profile_picture = "https://cdn.wisdomera.io/logo/amblem_saydam.png";
    } else {
      this.profile_picture = JSON.parse(prof_pic);
    }
    let query = "data_type=wdm20&location=wisdom";
    OptionService.get_last_wdm_of_this_type(query).then((resp) => {
      if (resp.data.status === "success") {
        this.option_data["wdm20"] = resp.data.result;
      }
    });
    this.f_checkConversation();
    this.d_showUserMode = "all";
  },
  mounted () {
    try {
      let x = JSON.parse(localStorage.getItem("meeting"));
      this.d_meetingData = x ? x : "";
    } catch (err) {}
    this.f_createParticipantList();
    if (!this.profile_picture ||
      this.profile_picture === undefined ||
      this.profile_picture === "undefined" ||
      this.profile_picture === ""
    ) {
      this.profile_picture = "img/doctor_man.png";
    }
    this.$options.sockets.onopen = function (event) {
      let meeting_data = JSON.parse(localStorage.getItem("meeting"));
      if (meeting_data && meeting_data["id"]) {
        let data = { id: meeting_data["id"] };
        MessengerService.get_meeting_data(data).then((resp) => {
          if (resp.data.status === "success") {
            if (resp.data.result.wdmr_69) {
              localStorage.setItem(
                "meeting",
                JSON.stringify(resp.data.result.wdmr_69)
              );
              this.d_meetingData = resp.data.result.wdmr_69;
              this.f_createParticipantList();
            } else {
              localStorage.removeItem("meeting");
            }
          }
        });
      }
    };
    this.$options.sockets.onmessage = function (message) {
      let socket_data = JSON.parse(message.data);
      if (socket_data.type === "meeting_share_screen") {
        if (this.d_showMessengerMode === "share_screen") {
          let vid_element = document.createElement("video");
          vid_element.id = "video";
          vid_element.src = socket_data.screen_video;
          // vid_element.style.width = '100%';
          let vid_height = "100%";
          let x = document.getElementById("video_element");
          if (x) {
            vid_height = x.offsetHeight.toString() + "px";
          }
          vid_element.style.height = vid_height;
          vid_element.style.backGround = "transparent";
          vid_element.autoplay = "autoplay";
          vid_element.volume = 0.2;
          // vid_element.innerHTML = 'Your browser does not support the video tag.';
          vid_element.load();
          setTimeout(
            function () {
              if (
                document.getElementById("video_element") &&
                vid_element.readyState === 4
              ) {
                // console.log('height ', document.getElementById('video_element').offsetHeight);
                document.getElementById("video_element").innerHTML = "";
                document
                  .getElementById("video_element")
                  .appendChild(vid_element);
              }
            }.bind(this),
            250
          );
        } else {
          this.d_openCloseLight.show_messenger_mode = true;
          // var list = document.getElementById("video_element");   // Get the <ul> element with id="myList"
          // list.removeChild(list.childNodes[0]);
          setTimeout(
            function () {
              // let vid =
              this.d_openCloseLight.show_messenger_mode = false;
            }.bind(this),
            1000
          );
        }
      }
      if (socket_data.type === "meeting_share_camera") {
        // console.log('this is camera websocket data', socket_data);
        if (this.d_showMessengerMode === "camera_screen") {
          let vid_element = document.createElement("video");
          vid_element.id = "video";
          vid_element.src = socket_data.screen_video;
          // vid_element.style.width = '100%';
          let vid_width = "100%";
          let x = document.getElementById("camera_area");
          // // console.log(x.offsetWidth);
          if (x) {
            vid_width = (x.offsetWidth / 2).toString() + "px";
          }
          vid_element.style.width = "300px";
          vid_element.style.backGround = "transparent";
          vid_element.autoplay = "autoplay";
          vid_element.volume = 0.2;
          // vid_element.innerHTML = 'Your browser does not support the video tag.';
          vid_element.load();
          setTimeout(
            function () {
              if (
                document.getElementById("camera_" + socket_data.send_by) &&
                vid_element.readyState === 4
              ) {
                // // console.log('height ', document.getElementById('camera_' + socket_data.send_by).offsetHeight);
                document.getElementById(
                  "camera_" + socket_data.send_by
                ).innerHTML = "";
                document
                  .getElementById("camera_" + socket_data.send_by)
                  .appendChild(vid_element);
              }
            }.bind(this),
            250
          );
        }
      }
      // // console.log(socket_data);
      if (socket_data.type === "meeting_share_audio") {
        // console.log('this is meeting share audio websocket data', socket_data)
        // // console.log('meeting_share_audio ', socket_data.send_by);
        if (this.d_talkingUsers.indexOf(socket_data.send_by) !== -1) {
          // console.log('this is meeting share audio websocket data', socket_data)
          let audio_element = document.createElement("audio");
          audio_element.autoplay = "autoplay";
          // // console.log(socket_data.audio ? socket_data.audio.substring(0, 30) : 'no audio');
          audio_element.src = socket_data.audio;
          audio_element.load();
          let audio_id = "audio_" + socket_data.send_by;
          let audio_location = document.getElementById(audio_id);
          // // console.log(audio_location);
          setTimeout(
            function () {
              document.getElementById(audio_id).innerHTML = "";
              document.getElementById(audio_id).appendChild(audio_element);
            }.bind(this),
            200
          );
        }
      }
      if (socket_data.type === "meeting_info") {
        if (socket_data.data.type === "leave_meeting") {
          // // console.log('socket_data.data.leave_type ', socket_data.data.leave_type);
          if (socket_data.leave_type === "all") {
            localStorage.removeItem("meeting");
            this.d_meetingData = "";
          } else if (socket_data.leave_type === "this") {
            if (this.d_meetingData && this.d_meetingData.id) {
              for (let i in this.d_meetingData.data.general.participant.list) {
                if (
                  this.d_meetingData.data.general.participant.list[i]
                  .participant_name.val.value === socket_data.user
                ) {
                  this.d_meetingData.data.general.participant.list[
                    i
                  ].participant_status.val = {
                    label: "pasif",
                    value: "passive",
                  };
                  break;
                }
              }
              localStorage.setItem(
                "meeting",
                JSON.stringify(this.d_meetingData)
              );
            }
          }
        }
        if (socket_data.data.type === "meeting_share") {
          if (socket_data.data.wdmr_69) {
            // // console.log('meeting_share socket_data.wdmr_69 is filled');
            localStorage.setItem(
              "meeting",
              JSON.stringify(socket_data.wdmr_69)
            );
            this.d_meetingData = socket_data.data.wdmr_69;
          } else {
            // // console.log('meeting_share socket_data.wdmr_69 is freeeeeeeeeeeeee');
            this.d_meetingData = "";
          }
          this.f_createParticipantList();
        }
        if (socket_data.data.type === "join_meeting") {
          localStorage.setItem(
            "meeting",
            JSON.stringify(socket_data.data.wdmr_69)
          );
          this.d_meetingData = socket_data.data.wdmr_69;
          this.f_createParticipantList();
        }
        this.$forceUpdate();
      }
      if (socket_data.type === "message") {
        this.f_messagesScrollFunc();
        this.f_playMessageNotification();
        // console.log("this is message socket data", socket_data);
        // our message list is this.d_selectedWdm213
        /*
          this.d_selectedWdm213 = {
            'wdm213_474': []
          }
        */
        // console.log("this is selected mes index", this.selected_mes_ind.toString() ); 
        // console.log("this is socket data wdm 214 key ", socket_data["wdm214_key"] );
        // console.log(
        //   "this is selected wdm214",
        //   this.c_filtered[this.selected_mes_ind]["key"]
        // );
        if (this.selected_mes_ind.toString() != "" && socket_data["wdm214_key"] === this.c_filtered[this.selected_mes_ind]["key"]) {
          if (!this.d_selected_wdm213[socket_data["wdm213_key"]]) {
            this.d_selected_wdm213[socket_data["wdm213_key"]] = [];
          }
          // console.log("it entered if query 872");
          this.d_selected_wdm213[socket_data["wdm213_key"]].push(
            socket_data.message
          );
        } else {
          // console.log("it couldnt entered if query 875");
          let is_current = false;
          for (let i in this.c_filtered) {
            if (this.c_filtered[i].key === socket_data["wdm214_key"]) {
              is_current = true;
              this.c_filtered[i]["new_message"] = 1;
              break;
            }
          }
          if (!is_current) {
            this.c_filtered.push({
              'key': socket_data["wdm214_key"],
              'label': {
                'conversation_name': socket_data["sender_name"],
                'new_message': 1
              }
            })
          }
        }
        this.$forceUpdate();
      }
      if (
        socket_data.type && ["messenger_new_message"].indexOf(socket_data.type) !== -1
      ) {
        if (socket_data.data.message_data.group_key) {
          for (let mes_ind in this.messenger_data) {
            if (
              this.selected_mes_ind !== "" &&
              this.messenger_data[mes_ind].group &&
              this.messenger_data[mes_ind].group.key ===
              socket_data.data.message_data.group_key &&
              parseInt(this.selected_mes_ind) === parseInt(mes_ind)
            ) {
              let x = JSON.parse(JSON.stringify(this.messenger_data));
              for (let dy_ind in x[mes_ind].day_list) {
                if (x[mes_ind].day_list[dy_ind].new) {
                  this.$delete(x[mes_ind].day_list[dy_ind], "new");
                }
              }
              this.$store.commit("messenger_data", x);
              this.$forceUpdate();
              this.f_messagesScrollFunc();
              break;
            }
          }
        } else {
          for (let mes_ind in this.messenger_data) {
            if (
              this.selected_mes_ind !== "" &&
              this.messenger_data[mes_ind].user &&
              this.messenger_data[mes_ind].user.username ===
              socket_data.data.user &&
              parseInt(this.selected_mes_ind) === parseInt(mes_ind)
            ) {
              let x = JSON.parse(JSON.stringify(this.messenger_data));
              for (let dy_ind in x[mes_ind].day_list) {
                if (x[mes_ind].day_list[dy_ind].new) {
                  this.$delete(x[mes_ind].day_list[dy_ind], "new");
                }
              }
              this.$store.commit("messenger_data", x);
              this.$forceUpdate();
              this.f_messagesScrollFunc();
              break;
            }
          }
        }
      }
    };

    this.f_messagesScrollFunc();
    if (this.polestar) {
      this.d_showAiRobots = true;
      this.f_recommendText();
    }
  },
  methods: {
    f_getProfilePictureFromWdm214: function (wdm214_obj) {
      try {
        let wdm22_id = wdm214_obj.participants[0];
        if (!this.d_profilePictureObj[wdm22_id] || (this.d_profilePictureObj[wdm22_id] && this.d_profilePictureObj[wdm22_id] === '')) {
          return 'https://cdn.wisdomera.io/logo/amblem_saydam.png';
        } else {
          return this.d_profilePictureObj[wdm22_id];
        }
      } catch (err) {
        return 'https://cdn.wisdomera.io/logo/amblem_saydam.png';
      }
    },
    f_getProfilePictureFromWdm22Id: function (wdm22_id) {
      if (!this.d_profilePictureObj[wdm22_id] || (this.d_profilePictureObj[wdm22_id] && this.d_profilePictureObj[wdm22_id] === '')) {
        return 'https://cdn.wisdomera.io/logo/amblem_saydam.png';
      } else {
        return this.d_profilePictureObj[wdm22_id];
      }
    },
    f_playMessageNotification: function () {
      document.getElementById('myAudio').play();
    },
    f_sendMeetingInvitation: function () {
      let id_list = this.d_selectedHighlightIds;
      let present_conversation = this.d_checkConversation["personal"];
      for (let ind in id_list) {
        console.log("here is an id", id_list[ind]);
        for (let ind2 in present_conversation) {
          if (id_list[ind] === present_conversation[ind2]["id"]) {
            let wdm214_key = present_conversation[ind2]["last_wdm214"];
            this.d_sendText =
              this.d_meetingData["id"] + "\n  id'li  toplantıma davetlisiniz";
            this.f_sendMyMessage();
            break;
          }
        }
      }
    },
    f_copyToClipboard: function () {
      var tempInput = document.createElement("input");
      tempInput.value = this.d_meetingData["id"];
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    },
    f_clearGroupData: function () {
      this.d_createGroupModal = false;
      this.d_meetingInvitationModal = false;
      this.d_groupName = "";
      this.d_groupHighlight = [];
      this.d_searchText = "";
    },
    f_offlineClearNotification: function () {
      var data = {
        'wdm214_key': this.d_myWdm214Key,
      };
      ConversationService.offline_clear_notification(data).then((resp) => {});
    },
    f_offlineMessageNotification: function () {
      var data = {
        'wdm214_key': this.d_myWdm214Key,
      };
      ConversationService.offline_message_notification(data).then((resp) => {});
    },
    f_groupParticipantsStyle: function (obj_ind) {
      var style = "";

      if (this.d_groupHighlight.includes(obj_ind)) {
        style =
          "cursor: pointer; min-height: 30px; background-color: #c6fcd8; margin: 0px; border-bottom: solid 1px #a8efc1;";
      } else {
        style =
          "cursor: pointer; min-height: 30px; margin: 0px; border-bottom: solid 1px #a8efc1;";
      }
      return style;
    },

    f_groupDecide: function (obj_ind) {
      if (this.d_groupHighlight.includes(obj_ind)) {
        this.f_removeGroup(obj_ind);
      } else {
        this.f_addGroup(obj_ind);
      }
    },
    f_addGroup: function (obj_ind) {
      this.d_groupHighlight.push(obj_ind);
    },
    f_removeGroup: function (obj_ind) {
      var ind = this.d_groupHighlight.indexOf(obj_ind);
      this.d_groupHighlight.splice(ind, 1);
    },
    f_changeGroupMode: function () {
      if (this.d_groupMode == true) {
        this.d_groupMode = false;
      } else if (this.d_groupMode == false) {
        this.d_groupMode = true;
      }
    },
    f_checkIfPresents: function (obj, obj_ind) {
      let user_found = false;
      if (this.d_checkConversation["personal"]) {
        let ba = this.d_checkConversation["personal"];
        for (let ind = 0; ind < ba.length; ind++) {
          if (obj["key"] === ba[ind]["id"]) {
            this.d_selectedSearchData = "old";
            this.f_SelectedMessage(ba[ind]["last_wdm214"]);
            user_found = true;
            break;
          }
        }
      }
      if (!user_found) {
        this.f_createNewWdmr214(obj["label"], obj["key"]);
        this.d_searchText = "";
      }
      this.f_selectThisUser(obj_ind, obj["key"]);
    },
    f_searchMyUser: function () {
      var data = {};
      data["search_text"] = this.d_searchText;
      ConversationService.search_user(data).then((resp) => {
        this.d_searchResult = resp.data.result.wdm22;
        this.d_profilePictureObj = {...this.d_profilePictureObj, ...resp.data.result.pictures};
      });
      clearTimeout(this.d_searchTimer);
    },
    f_checkConversation: function () {
      let data = {};
      ConversationService.check_personal_conversation(data).then((resp) => {
        this.d_checkConversation = resp.data.result;
      });
    },
    f_nameStripper: function (text) {
      var fullName = this.user["first_name"] + " " + this.user["last_name"];
      var remaining = text.toString().replace(",", "").replace(fullName, "");
      return remaining;
    },
    f_recipientName: function (text) {
      let participants = text.split(" ");
      let new_list = [];
      for (var i in participants) {
        if (i != this.user["username"]) {
          new_list.push(i);
        }
      }
      pruned_text = new_list.join(" ");
      return pruned_text;
    },
    f_getEligibleWdm22List: function () {
      let data = {};
      ConversationService.get_eligible_wdm22_list(data).then((resp) => {
        var myNameList = [];
        var myIndexList = [];
        var myResult = resp.data.result;
        for (var i in myResult) {
          myNameList.push(myResult[i]["label"]);
          myIndexList.push(myResult[i]["value"]);
        }
        this.d_userList = myNameList;
        this.d_userIndexList = myIndexList;
        this.d_userObject = myResult;
        this.d_myUserObjects = resp.data.result;
        return resp.data.result.toString();
      });
    },
    f_createNewWdmr214: function (name, id) {
      let data = {};
      data.type = "personal";
      data.name =
        data.type == "personal" ? name + "," + this.user["first_name"] + " " + this.user["last_name"] : "grubun ismi";
      data.participants = [];
      data.participants.push({ id: id, name: name });
      data.participants.push({
        id: this.user["id"],
        name: this.user["first_name"] + " " + this.user["last_name"],
      });
      data.conversation_starter = {
        id: this.user["id"],
        name: this.user["first_name"] + " " + this.user["last_name"],
      };
      var wdm_214_key = "";
      ConversationService.create_new_wdmr214(data).then((resp) => {
        var wdm214_data = resp.data.result;
        var wdm214_key = wdm214_data["type"] + "_" + wdm214_data["id"];
        this.d_searchText = "";
        this.f_get_my_wdmr_214_list();
        this.f_getEligibleWdm22List();
        this.$forceUpdate();
        return wdm214_key;
      });
    },
    f_createGroupDoubleClick: function () {
      if (this.d_groupHighlight.length > 0 && this.d_groupName) {
        let data = {};
        data.type = "group";
        data.name = this.d_groupName;
        data.participants = [];
        data.participants.push({
          'id': this.user["id"],
          'name': this.user["first_name"] + " " + this.user["last_name"],
        });
        data.conversation_starter = {
          'id': this.user["id"],
          'name': this.user["first_name"] + " " + this.user["last_name"],
        };
        for (var i in this.d_groupHighlight) {
          var ind = this.d_groupHighlight[i];
          var obj = this.d_searchResult[ind];
          var new_obj = {
            'id': obj["key"],
            'name': obj["label"],
          };
          data.participants.push(new_obj);
        }
        ConversationService.create_new_wdmr214(data).then((resp) => {
          alert("yeni grup oluşturuldu !");
        });
        this.d_searchText = "";
        this.d_groupMode = false;
        this.d_groupName = "";
      } else {
        alert(
          "lütfen grubunuza bir isim koyunuz ve en az bir kullanıcı ekleyiniz  !"
        );
      }
    },
    f_get_my_wdmr_214_list: function () {
      let data = {};
      ConversationService.get_my_wdmr_214_list(data).then((resp) => {
        this.d_userObject = resp.data.result.wdm214;
        this.d_myWdm214Objects = resp.data.result.wdm214;
        this.d_profilePictureObj = {...this.d_profilePictureObj, ...resp.data.result.pictures};
      });
    },
    f_SelectedMessage: function (wdm214_key) {
      this.d_myWdm214Key = wdm214_key;
      let data = {};
      data["wdm214_key"] = wdm214_key;
      ConversationService.show_wdm213(data).then((resp) => {
        this.d_selected_wdm213 = resp.data.result;
      });
    },
    f_meetingShare: function (type) {
      let data = {
        type: type,
        share_mode: "",
        id: this.d_meetingData.id,
      };
      if (type === "audio") {
        if (this.d_sharingAudio) {
          data.share_mode = "yes";
        } else {
          data.share_mode = "no";
        }
      } else if (type === "camera") {
        if (this.d_sharingCamera) {
          data.share_mode = "yes";
        } else {
          data.share_mode = "no";
        }
      } else if (type === "screen") {
        if (this.d_sharingScreen) {
          data.share_mode = "yes";
        } else {
          data.share_mode = "no";
        }
      }
      MessengerService.meeting_share(data).then((resp) => {
        if (resp.data.status === "success") {
          this.d_meetingData = resp.data.result.wdmr_69;
          this.f_createParticipantList();
          localStorage.setItem(
            "meeting",
            JSON.stringify(resp.data.result.wdmr_69)
          );
        } else {
          localStorage.removeItem("meeting");
          alert("Error: ", resp.data.message);
        }
      });
    },
    f_stopScreenShare: function () {
      this.d_sharingScreen = false;
      this.d_showMessengerMode = "message_screen";
      if (mediaScreenRecorder && mediaScreenRecorder.status === "recording") {
        mediaScreenRecorder.stop();
      }
      clearInterval(this.d_shareScreenInterval);
      this.f_meetingShare("screen");
    },
    f_stopAudioShare: function () {
      this.d_sharingAudio = false;
      if (mediaAudioRecorder && mediaAudioRecorder.status === "recording") {
        mediaAudioRecorder.stop();
      }
      clearInterval(this.d_shareAudioInterval);
      this.f_meetingShare("audio");
    },
    f_stopCameraShare: function () {
      this.d_sharingCamera = false;
      if (mediaCameraRecorder && mediaCameraRecorder.status === "recording") {
        mediaCameraRecorder.stop();
      }
      clearInterval(this.d_shareCameraInterval);
      this.f_meetingShare("camera");
    },
    f_shareScreen: function () {
      this.d_sharingScreen = true;
      let vm = this;

      function file_read(blob) {
        var reader = new FileReader();
        reader.addEventListener(
          "load",
          function () {
            let client_data = {
              type: "meeting_share_screen",
              screen_video: reader.result,
              active_participants: vm.d_activeMeetingParticipants,
              send_by: vm.d_wdm22.id,
            };
            vm.$socket.send(JSON.stringify(client_data));
          },
          false
        );
        reader.readAsDataURL(blob);
      }
      async function startCapture() {
        await navigator.mediaDevices
          .getDisplayMedia({ video: { cursor: "always" }, audio: false, width: 720, height: 1280 })
          .then(function (stream) {
            vm.f_meetingShare("screen");
            var options;
            let is_type_supported = MediaRecorder.isTypeSupported(
              "video/webm;codecs=vp8"
            );
            if (is_type_supported) {
              options = { mimeType: "video/webm;codecs=vp8" };
              mediaScreenRecorder = new MediaRecorder(stream, options);
            } else {
              mediaScreenRecorder = new MediaRecorder(stream);
            }
            mediaScreenRecorder = new MediaRecorder(stream, options);
            mediaScreenRecorder.start();
            var chunks = [];
            mediaScreenRecorder.ondataavailable = function (e) {
              chunks.push(e.data);
            };
            mediaScreenRecorder.onstop = function (e) {
              if (chunks.length > 0) {
                let blob = null;
                if (is_type_supported) {
                  blob = new Blob(chunks, { type: "video/webm;codecs=vp8" });
                } else {
                  blob = new Blob(chunks, { type: "video/webm" });
                }
                file_read(blob);
              }
            };
            vm.d_shareScreenInterval = setInterval(() => {
              if (mediaScreenRecorder.state === "recording") {
                if (vm.d_shareScreenInterval) {
                  mediaScreenRecorder.stop();
                  chunks = [];
                  mediaScreenRecorder.start();
                } else {
                  mediaScreenRecorder.stop();
                }
              } else {
                vm.f_stopScreenShare();
                clearInterval(vm.d_shareScreenInterval);
              }
            }, 500);
          });
      }
      startCapture();
    },
    f_shareAudio: function () {
      this.d_sharingAudio = true;
      let vm = this;

      function file_read(blob) {
        var reader = new FileReader();
        reader.addEventListener(
          "load",
          function () {
            let client_data = {
              type: "meeting_share_audio",
              audio: reader.result,
              active_participants: vm.d_activeMeetingParticipants,
              send_by: vm.d_wdm22.id,
            };
            vm.$socket.send(JSON.stringify(client_data));
          },
          false
        );
        reader.readAsDataURL(blob);
      }
      async function startAudio() {
        await navigator.mediaDevices
          .getUserMedia({
            audio: {
              // echoCancellation: true,
              // noiseSuppression: true,
              sampleRate: 16000,
            },
          })
          .then(function (stream) {
            stream.getAudioTracks()[0].applyConstraints({ echoCancellation: true, noiseSuppression: true });
            vm.f_meetingShare("audio");
            var options;
            let is_type_supported = MediaRecorder.isTypeSupported(
              "audio/webm;codecs=vp8"
            );
            if (is_type_supported) {
              options = { mimeType: "audio/webm;codecs=vp8" };
              mediaAudioRecorder = new MediaRecorder(stream, options);
            } else {
              mediaAudioRecorder = new MediaRecorder(stream);
            }
            // mediaAudioRecorder.setOutputFormat(MediaRecorder.OutputFormat.MPEG_4);
            // mediaAudioRecorder.setAudioEncoder(MediaRecorder.OutputFormat.AMR_NB);
            // mediaAudioRecorder.setAudioEncodingBitRate(16 * 44100);
            // mediaAudioRecorder.setAudioSamplingRate(44100);
            mediaAudioRecorder.start();
            var audio_chunks = [];
            mediaAudioRecorder.ondataavailable = function (e) {
              audio_chunks.push(e.data);
              // console.log('audio_chunks ', audio_chunks);
            };
            mediaAudioRecorder.onstop = function (e) {
              // // console.log('audio_chunks ', audio_chunks);
              if (audio_chunks.length > 0) {
                // let blob = new Blob(audio_chunks, { type: "video/mp4" });
                let blob = null;
                if (is_type_supported) {
                  blob = new Blob(audio_chunks, {
                    type: "audio/webm;codecs=vp8",
                  });
                } else {
                  blob = new Blob(audio_chunks, { type: "audio/webm" });
                }
                file_read(blob);
              }
            };
            vm.d_shareAudioInterval = setInterval(() => {
              if (mediaAudioRecorder.state === "recording") {
                if (vm.d_shareAudioInterval) {
                  mediaAudioRecorder.stop();
                  audio_chunks = [];
                  // stream.removeTrack(stream.getAudioTracks()[0]);
                  mediaAudioRecorder.start();
                } else {
                  mediaAudioRecorder.stop();
                }
              } else {
                stream.getAudioTracks()[0].enabled = false;
                vm.f_stopAudioShare();
                clearInterval(vm.d_shareAudioInterval);
              }
            }, 750);
          });
      }
      startAudio();
    },
    f_shareCamera: function () {
      this.d_sharingCamera = true;
      let vm = this;

      function file_read(blob) {
        var reader = new FileReader();
        reader.addEventListener(
          "load",
          function () {
            let client_data = {
              type: "meeting_share_camera",
              video: reader.result,
              active_participants: vm.d_activeMeetingParticipants,
              send_by: vm.d_wdm22.id,
            };
            vm.$socket.send(JSON.stringify(client_data));
          },
          false
        );
        reader.readAsDataURL(blob);
      }
      async function startCamera() {
        await navigator.mediaDevices
          .getUserMedia({ video: { width: 426, height: 240 } })
          .then(function (stream) {
            vm.f_meetingShare("camera");
            var options;
            let is_type_supported = MediaRecorder.isTypeSupported(
              "video/webm;codecs=vp8"
            );
            if (is_type_supported) {
              options = { mimeType: "video/webm;codecs=vp8" };
              mediaCameraRecorder = new MediaRecorder(stream, options);
            } else {
              mediaCameraRecorder = new MediaRecorder(stream);
            }
            // mediaCameraRecorder.setOutputFormat(MediaRecorder.OutputFormat.MPEG_4);
            // mediaCameraRecorder.setAudioEncoder(MediaRecorder.OutputFormat.AMR_NB);
            // mediaCameraRecorder.setAudioEncodingBitRate(16 * 44100);
            // mediaCameraRecorder.setAudioSamplingRate(44100);
            mediaCameraRecorder.start();
            var camera_chunks = [];
            mediaCameraRecorder.ondataavailable = function (e) {
              camera_chunks.push(e.data);
            };
            mediaCameraRecorder.onstop = function (e) {
              // // console.log('camera_chunks ', camera_chunks);
              if (camera_chunks.length > 0) {
                // let blob = new Blob(camera_chunks, { type: "video/mp4" });
                let blob = null;
                if (is_type_supported) {
                  blob = new Blob(camera_chunks, {
                    type: "video/webm;codecs=vp8",
                  });
                } else {
                  blob = new Blob(camera_chunks, { type: "video/webm" });
                }
                file_read(blob);
              }
            };
            vm.d_shareCameraInterval = setInterval(() => {
              if (mediaCameraRecorder.state === "recording") {
                if (vm.d_shareCameraInterval) {
                  mediaCameraRecorder.stop();
                  camera_chunks = [];
                  // stream.removeTrack(stream.getAudioTracks()[0]);
                  mediaCameraRecorder.start();
                } else {
                  mediaCameraRecorder.stop();
                }
              } else {
                vm.f_stopCameraShare();
                clearInterval(vm.d_shareCameraInterval);
              }
            }, 500);
          });
      }
      startCamera();
    },
    f_joinMeeting: function (meeting_id) {
      localStorage.removeItem("meeting");
      let data = {
        id: meeting_id,
      };
      MessengerService.join_meeting(data).then((resp) => {
        if (resp.data.status === "success") {
          if (resp.data.result.wdmr_69) {
            this.d_meetingData = resp.data.result.wdmr_69;
            this.f_createParticipantList();
            localStorage.setItem(
              "meeting",
              JSON.stringify(resp.data.result.wdmr_69)
            );
          } else {
            alert(
              "Aktif toplantı bulunamadı. Lütfen toplantı bilgisini kontrol ediniz."
            );
          }
        } else {
          alert("Error: ", resp.data.message);
        }
      });
    },
    f_createParticipantList: function () {
      this.d_activeMeetingParticipants = [];
      let anyone_share = false;
      if (this.d_meetingData && this.d_meetingData.id) {
        for (let i in this.d_meetingData.data.general.participant.list) {
          if (
            this.d_meetingData.data.general.participant.list[i]
            .participant_status.val.value === "active"
          ) {
            this.d_activeMeetingParticipants.push(
              this.d_meetingData.data.general.participant.list[i]
              .participant_name.val.value
            );
          }
          // IF THIS IS NOT THE SHARING USER, AND SOMEONE SHARES,
          if (
            this.d_meetingData.data.general.participant.list[i]
            .participant_screen_sharing.val.value === "yes" &&
            this.d_meetingData.data.general.participant.list[i].participant_name
            .val.value !== this.d_wdm22.id
          ) {
            anyone_share = true;
          }
          if (
            this.d_meetingData.data.general.participant.list[i]
            .participant_audio.val.value === "yes" &&
            this.d_meetingData.data.general.participant.list[i].participant_name
            .val.value !== this.d_wdm22.id
          ) {
            this.d_talkingUsers.push(
              this.d_meetingData.data.general.participant.list[i]
              .participant_name.val.value
            );
          } else if (
            this.d_meetingData.data.general.participant.list[i]
            .participant_audio.val.value !== "yes"
          ) {
            let audio_user_index = this.d_talkingUsers.indexOf(
              this.d_meetingData.data.general.participant.list[i]
              .participant_name.val.value
            );
            if (audio_user_index !== -1) {
              this.d_talkingUsers.splice(audio_user_index, 1);
            }
          }
          if (
            this.d_meetingData.data.general.participant.list[i]
            .participant_camera.val.value === "yes"
          ) {
            this.d_cameraUsers.push(
              this.d_meetingData.data.general.participant.list[i]
              .participant_name.val.value
            );
          } else {
            let camera_user_index = this.d_cameraUsers.indexOf(
              this.d_meetingData.data.general.participant.list[i]
              .participant_name.val.value
            );
            if (camera_user_index !== -1) {
              this.d_cameraUsers.splice(camera_user_index, 1);
            }
          }
        }
      }
      this.d_otherOneShare = anyone_share;
      if (this.d_otherOneShare === false && this.d_sharingScreen === false) {
        if (this.d_showMessengerMode === "share_screen") {
          this.d_showMessengerMode = "message_screen";
        }
        try {
          document.getElementById("video_element").innerHTML = "";
        } catch (err) {}
      }
      this.$forceUpdate();
    },
    f_leaveMeeting: function (type) {
      let data = {
        'leave_type': type, // "this" or "all"
        'id': this.d_meetingData.id,
      };
      MessengerService.leave_meeting(data).then((resp) => {
        if (resp.data.status === "success") {
          this.d_meetingData = "";
          localStorage.removeItem("meeting");
        } else {
          alert("Error: ", resp.data.message);
        }
      });
    },
    f_createNewMeeting: function () {
      let data = {
        data: {
          'id': "new",
          'label': this.d_meetingLabel,
        },
      };
      MessengerService.save_meeting(data).then((resp) => {
        if (resp.data.status === "success") {
          this.d_meetingData = resp.data.result.wdmr_69;
          localStorage.setItem("meeting", JSON.stringify(this.d_meetingData));
        } else {
          alert("Error: ", resp.data.message);
        }
      });
    },
    f_calculateStyle: function () {
      let style = "";
      let h = 230;
      style = "overflow-y: auto; height: " + (window.innerHeight - h).toString() + "px";
      return style;
    },
    f_calculateStyleMessageScreen: function () {
      let style = "";
      let x = "";
      if (this.d_screenSharingModejFullScreen) {
        x = 50;
      } else {
        x = 310;
      }
      style = "border: solid 1px #adffdc; border-radius: 5px; padding: 5px; overflow-x: hidden; overflow-y: auto; height: " + (window.innerHeight - x).toString() + "px";
      return style;
    },
    f_showChars: function (text, char_count) {
      if (text.length > 15) {
        return text.substring(0, 15) + "...";
      } else {
        return text.substring(0, 15);
      }
    },
    addEventListenerToMessageListScroll: function () {
      const handler = (evt) => {
        if (
          document.getElementById("messages-list") &&
          document.getElementById("messages-list").scrollTop === 0
        ) {
          this.d_pagination.current += 1;
          this.f_getMessagesList();
        }
      };
      if (this.d_showMessengerMode === "message_screen") {
        setTimeout(function () {
          var div = document.getElementById("messages-list");
          div.addEventListener("scroll", handler);
        }, 50);
      } else {
        document
          .getElementById("messages-list")
          .removeEventListener("scroll", handler);
      }
    },
    f_recommendText: function () {
      this.refreshIntervalId = setInterval(() => {
        this.d_recommendNow = [];
        this.d_recommendSelection.start += 2;
        this.d_recommendSelection.end += 2;
        if (
          this.d_recommendSelection.start >=
          this.d_recommendationSentenceList.length - 1
        ) {
          this.d_recommendSelection.start = 0;
          this.d_recommendSelection.end = 2;
        } else if (
          this.d_recommendSelection.end >
          this.d_recommendationSentenceList.length - 1
        ) {
          this.d_recommendSelection.end =
            this.d_recommendationSentenceList.length - 1;
        }
        for (
          let i = this.d_recommendSelection.start; i <= this.d_recommendSelection.end; i++
        ) {
          this.d_recommendNow.push(this.d_recommendationSentenceList[i]);
        }
        this.$forceUpdate();
      }, 5000);
    },
    DateFormat: function (date) {
      if (date) {
        if (date.indexOf("T") === -1) {
          return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm");
        } else {
          return moment(date, "YYYY-MM-DDTHH:mm:ss").format("DD.MM.YYYY HH:mm");
        }
      } else {
        return "";
      }
    },
    f_messagesScrollFunc: function () {
      setTimeout(function () {
        document.getElementById("messages-list").scrollTop = document.getElementById("messages-list").scrollHeight + 20;
        this.$forceUpdate();
      }.bind(this), 500);
    },
    f_sendMyMessage: function () {
      if (this.d_sendText !== "") {
        let data = {};
        // self.arguments = {
        //         'sender':{
        //             'id':1,
        //             'username':'wisdom',
        //              'firstname':'wisdom',
        //              'lastname':'wisdom',

        //       },
        //       'wdm214_key':'wdm213_7',
        //       'message':'mesaj gondermek ne kadar zormus arkadas'

        //   }
        data["sender"] = {
          id: this.user["id"],
          username: this.user["username"],
          firstname: this.user["first_name"],
          lastname: this.user["last_name"],
        };
        data["wdm214_key"] = this.d_myWdm214Key;
        data["message"] = this.d_sendText;
        this.d_sendText = "";
        ConversationService.add_message_wdm213(data).then((resp) => {
          // console.log('message send service is worked and the answer is '+ resp.data);
        });
      }
    },
    f_hasNotSeenMessage: function (mes) {
      for (let i in mes["day_list"]) {
        if (mes["day_list"][i].new) {
          return true;
        }
      }
      return false;
    },
    f_selectThisUser: function (mes_ind, key) {
      // console.log("mes_ind--->", mes_ind);
      // console.log("key--->", key);
      if (this.c_filtered[mes_ind]["new_message"]) {
        this.$delete(this.c_filtered[mes_ind], "new_message");
      }
      if (this.d_searchText === "") {
        this.f_SelectedMessage(key);
      }
      this.selected_mes_ind = mes_ind;
      this.d_pagination = {
        start: 0,
        end: 10,
        current: 1,
        perpage: 10,
      };
      // the event listener scroll handler in above function makes d_pagination.current += 1. The problem exists while we clicking any user, it looks at the scroll top.
      // If the scrollTop is 0, then we have to make the current = 1, else we make the current = 0 because handler already itself adds + 1 to d_pagination.current in its function.
      this.d_showMessengerMode = "message_screen";
      if (
        document.getElementById("messages-list") &&
        document.getElementById("messages-list").scrollTop === 0
      ) {
        this.d_pagination.current = 1;
      } else {
        this.d_pagination.current = 0;
      }

      this.f_messagesScrollFunc();

      this.f_offlineClearNotification();
    },
    f_searchUser: function (mes) {
      if (mes.user && mes.user.username === this.user.username) {
        return false;
      }
      if (this.d_showUserMode === "meeting") {
        if (this.d_meetingData && this.d_meetingData.id) {
          if (mes.user) {
            if (
              this.d_activeMeetingParticipants.indexOf(mes.user.username) === -1
            ) {
              return false;
            }
          } else {
            return false;
          }
        }
      }
      if (this.d_showUserMode === "my_hospital") {
        if (mes.user) {
          if (this.user.hospital_id !== mes.user.hospital_id) {
            return false;
          }
        } else {
          return false;
        }
      }
      if (this.d_showUserMode === "ai_robot") {
        if (mes.user) {
          if (!mes.user.ai_robot) {
            return false;
          }
        } else {
          return false;
        }
      }
      if (this.user_search) {
        let x = "";
        if (mes.user) {
          x = mes.user.first_name + mes.user.last_name;
        } else if (mes.group) {
          x = mes.group.label;
        }
        if (
          x
          .toLocaleLowerCase("tr")
          .indexOf(this.user_search.toLocaleLowerCase("tr")) !== -1
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
  watch: {
    d_groupHighlight: function () {
      if (this.d_groupHighlight.length != 0) {
        let ids_list = [];
        for (let ind in this.d_groupHighlight) {
          let search_ind = this.d_groupHighlight[ind];
          ids_list.push(this.d_searchResult[search_ind]["key"]);
        }
        this.d_selectedHighlightIds = ids_list;
      } else {
        this.d_selectedHighlightIds = [];
      }
    },
    selected_mes_ind: function () {
      this.d_visitedUsers.push(this.selected_mes_ind.toString());
    },
    d_wait: function () {
      if (!this.d_wait) {
        // console.log("serviceee runn");
        this.f_searchMyUser();
      }
    },
    d_searchText: function () {
      if (this.d_searchText != "") {
        this.d_groupHighlight = [];
        this.d_showUserMode = "conversations";
        // console.log('hey your text has changed');
        this.d_searchTimer = 0;
        this.d_wait = true;
        this.d_searchTimer = setTimeout(
          function () {
            this.c_filtered = this.d_searchResult;
            this.d_wait = false;
          }.bind(this),
          2000
        );
      } else {
        // console.log("lets refresh the past conversations", this.f_checkConversation() );
        this.f_checkConversation();
        this.f_get_my_wdmr_214_list();
        this.$forceUpdate();
      }
      // if(!this.d_wait){
      //
      // }
    },
    d_valTextTimer: function () {
      clearTimeout(this.d_searchTimer);
      this.d_searchTimer = 0;
      // console.log('data has changed');
      this.d_searchTimer = setTimeout(
        function () {
          this.f_searchMyUser();
        }.bind(this),
        2000
      );
    },
  },
  d_showMessengerMode: function () {
    if (this.d_showMessengerMode === "message_screen") {
      try {
        document.getElementById("video_element").innerHTML = "";
      } catch (err) {}
    }
  },
  d_showUserMode: function () {
    if (this.d_showUserMode === "conversations") {
      this.f_get_my_wdmr_214_list();
    } else if (this.d_showUserMode === "all") {
      this.f_getEligibleWdm22List();
    } else if (this.d_showUserMode === "complete") {
      this.d_userObject = this.c_filtered;
      // console.log('complete activated');
    }
  },

  components: {
    Modal,
    // WisdomDataModal
  },
};

</script>

<style type="text/css">
#messages-list::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #d9e9ea;
}

#messages-list::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#messages-list::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4788ac;
}

#messages-list {
  background-image: url("https://cdn.wisdomera.io/wconnect/chat_background.png");
}

*/ .full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

</style>

